import React, { } from "react"
import InformationDesk from "../components/informationdesk/InformationDesk"
import "../assets/styles/_index.scss"
import Favicon from "react-favicon"
import { Helmet } from "react-helmet"

const favicon_logo = require("../images/favicon.png");

const InformationPage = () => (
  <>
    <Favicon url={favicon_logo} />

    <Helmet>
      <meta charSet="utf-8" />
      <title>Propverse - Powered by Propex.AI</title>
      <link rel="canonical" href="https://www.propverse.ai/" />
    </Helmet>

    <InformationDesk />
  </>
)

export default InformationPage
