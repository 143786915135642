import React, { useState, useEffect } from "react"
import "./assets/styles/_index.scss"
import InfoSidebar from "../informationdesk/InfoSidebar"
import Container from 'react-bootstrap/Container'
import { ArrowRight } from 'react-bootstrap-icons'
import { Accordion, Card, Col, Button, Row } from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { Link } from "gatsby"
import Slider from "react-slick"
import Image from "react-bootstrap/Image"
import EnquiryPopup from "../enquirypopup/EnquiryPopUp"
import { Formik } from "formik"
import SuccessModal from "../SuccessModal/SuccesModal"
import configs from "../../Utils/configs" 
import { postAPICall } from "../../Utils/Network"

const info1 = require("/utility/assets/informationdesk/developers/Abhee-Ventures.png")
const info2 = require("/utility/assets/informationdesk/developers/Arsis.png")
const info3 = require("/utility/assets/informationdesk/developers/Assetz.png")
const info4 = require("/utility/assets/informationdesk/developers/BCD.png")
const info5 = require("/utility/assets/informationdesk/developers/Birla-Estates.png")
const info6 = require("/utility/assets/informationdesk/developers/Brigade.png")
const info7 = require("/utility/assets/informationdesk/developers/Casagrand.png")
const info8 = require("/utility/assets/informationdesk/developers/Geown.png")
const info9 = require("/utility/assets/informationdesk/developers/Goorej.png")
const info10 = require("/utility/assets/informationdesk/developers/Infracon-Pvt.png")

const info11 = require("/utility/assets/informationdesk/developers/Maa-Homes.png")
const info12 = require("/utility/assets/informationdesk/developers/Preeti-Developers.png")
const info13 = require("/utility/assets/informationdesk/developers/Ramky.png")
const info14 = require("/utility/assets/informationdesk/developers/Sowparnika.png")
const info15 = require("/utility/assets/informationdesk/developers/Sanjeevini-Group.png")
const info16 = require("/utility/assets/informationdesk/developers/Sattva.png")
const info17 = require("/utility/assets/informationdesk/developers/Shoba.png")
const info18 = require("/utility/assets/informationdesk/developers/Provident.png")
const info19 = require("/utility/assets/informationdesk/developers/ColiveAarra.png")
const info20 = require("/utility/assets/informationdesk/developers/Newry.png")

const info21 = require("/utility/assets/informationdesk/developers/Fortune.png")
const info22 = require("/utility/assets/informationdesk/developers/Hardrock.png")
const info23 = require("/utility/assets/informationdesk/developers/Kaman.png")


const ExpoIcon = require("/utility/assets/informationdesk/Expo_Icon.png")
const HomeIcon = require("/utility/assets/informationdesk/Home_Icon.svg")
const InfodeskIcon = require("/utility/assets/informationdesk/Infodesk_Icon.png")

const enquirynow = require("/utility/assets/informationdesk/icons/enquirynow.png")
const enquirylogo = require("/utility/assets/informationdesk/icons/EnquiryLogo.png")

const cardItems = [
    { id: 1, image: info1, link: "/developers/abhee-developer/" },
    { id: 2, image: info2, link: "/developers/arsis-developer/" },
    { id: 3, image: info3, link: "/developers/assetz-developer/" },
    { id: 4, image: info4, link: "/developers/bcd-emprasa-developer/" },
    { id: 5, image: info5, link: "/developers/birla-estates-developer/" },
    { id: 6, image: info6, link: "/developers/brigade-developer/" },
    { id: 7, image: info7, link: "/developers/casagrand-developer/" },
    { id: 8, image: info8, link: "/developers/geown-developer/" },
    { id: 9, image: info9, link: "/developers/godrej-developer/" },
    { id: 10, image: info10, link: "/developers/infracon-developer/" },
    { id: 11, image: info11, link: "/developers/maa-homes-developer/" },
    { id: 12, image: info12, link: "/developers/preeti-developer/" },
    { id: 13, image: info13, link: "/developers/ramky-developer/" },
    { id: 14, image: info14, link: "/developers/sowparnika-developer/" },
    { id: 15, image: info15, link: "/developers/sanjeevini-developer/" },
    { id: 16, image: info16, link: "/developers/sattva-developer/" },
    { id: 17, image: info17, link: "/developers/sobha-developer/" },
    { id: 18, image: info18, link: "/developers/provident-developer/" },
    { id: 19, image: info19, link: "/developers/aarra-developer/" },
    { id: 20, image: info20, link: "/developers/newry-developer/" },
    { id: 21, image: info21, link: "/developers/fortune-sensation-developer/" },
    { id: 22, image: info22, link: "/developers/hard-rock-developer/" },
    { id: 23, image: info23, link: "/developers/kaman-developer/" }
];

function InformationDesk() {
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);
    const [isOpenSV, setIsOpenSV] = useState(false)
    const [isOpenEN, setIsOpenEN] = useState(false)
    const [hoveredButton, setHoveredButton] = useState(null)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [placeholder, setPlaceholder] = React.useState("YYYY-MM-DD")

    const toggleRightSidebar = () => {
        setIsRightSidebarOpen(isRightSidebarOpen);
    };

    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    const handleButtonHover = buttonName => {
        setHoveredButton(buttonName)
    }

    const handleButtonLeave = () => {
        setHoveredButton(null)
    }

    const togglePopupSV = () => {
        setIsOpenSV(!isOpenSV)
    }

    const togglePopupEN = () => {
        setIsOpenEN(!isOpenEN)
    }

    // useEffect(() => {
    //     if (showSuccessModal) {
    //         const timer = setTimeout(() => {
    //             setShowSuccessModal(false)
    //         }, 3000)

    //         return () => {
    //             clearTimeout(timer)
    //         }
    //     }
    // }, [showSuccessModal])

    const closeModal = () => {
        setShowSuccessModal(false)
    }

    //Enquiry API_REQUEST
    const EnquiryFormSubmit = async values => {
        console.log(values)
        var config = { "Access-Control-Allow-Origin": "*" }
        var param = {
            Name: values.fullname,
            Email: values.email,
            Phone: values.phonenumber,
            Location: "",
            Budget: "",
            PickupDate: "",
            PickupTimeId: "",
            LeadSource: configs.LEAD_SOURCE_ENQUIRY,
            LandingPageId: configs.LANDING_PAGE_ID,
            PlatFormId: configs.PLATFORM_ID,
            IsIgnoreCRM: false,
        }
        var url = configs.ENQUIRY_API_URL()

        postAPICall(
            url,
            param,
            config,
            res => {
                // console.log(res.data);
                setIsOpenEN(false)
                setShowSuccessModal(true)
            },
            err => {
                // console.log(err);
            }
        )
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: "0px",
        centerMode: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    infinite: true,
                    speed: 500,
                    centerMode: true,
                    centerPadding: "0px",
                    slidesToShow: 3,
                },
            },

        ],

    }

    return (
        <>
            <div className="InformationDeskSection">
                <div className="d-none d-md-block">
                    <InfoSidebar
                        isOpen={isRightSidebarOpen}
                        toggleRightSidebar={toggleRightSidebar}
                    />
                </div>

                <Accordion defaultActiveKey="0" className="d-block d-md-none ProjectListAccordion ProjectListAccordionInfo ProjectDetailsList">

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Info desk
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Tabs
                                    defaultActiveKey="aboutprop"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="aboutprop" title="About Propverse">
                                        <div className="ReadMoreSection">
                                            <h4 className='m-0'>Info desk</h4>
                                            <p className="mb-0">
                                                <p className="AboutExpoPara">The Sobha Group was founded in 1986 by brothers Irfan Razack, Rezwan Razack and Noaman Razack. The group is one of India’s leading real estate companies. The Prestige Group have taken the South Indian real estate market by storm.</p>
                                            </p>

                                            {isShowMore && (
                                                <p className="mb-0">

                                                    <p className="AboutExpoPara">The Sobha Group was founded in 1986 by brothers Irfan Razack, Rezwan Razack and Noaman Razack. The group is one of India’s leading real estate companies. The Prestige Group have taken the South Indian real estate market by storm. They are known as the best real estate developers in Karnataka.</p>

                                                    <p className="AboutExpoPara">The company expanded itself with fields which may or may not be related to real estate. The Morph Design Company provides exceptional interior design services.</p>

                                                    <p className="AboutExpoPara">The Sobha Group was founded in 1986 by brothers Irfan Razack, Rezwan Razack and Noaman Razack. The group is one of India’s leading real estate companies. The Prestige Group have taken the South Indian real estate market by storm. They are known as the best real estate developers in Karnataka.</p>

                                                    <p className="AboutExpoPara">The company expanded itself with fields which may or may not be related to real estate. The Morph Design Company provides exceptional interior design services. </p>

                                                    <p className="AboutExpoPara">The Shoba Group was founded in 1986 by brothers Irfan Razack, Rezwan Razack and Noaman Razack. The group is one of India’s leading real estate companies. The Prestige Group have taken the South Indian real estate market by storm. They are known as the best real estate developers in Karnataka.</p>

                                                    <p className="AboutExpoPara">The company expanded itself with fields which may or may not be related to real estate. The Morph Design Company provides exceptional interior design services. </p>
                                                </p>
                                            )}
                                            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>

                                            <div className="infoRatingSection infoRatingSectionMob">
                                                <Container>
                                                    <Row lg={12} className="infoRatingSectionWrap">
                                                        <Col lg={4} className="infoRatingSectionGrid">
                                                            <h5 className="textNumber infoRatingSectionText">20</h5>
                                                            <p>Top Developers</p>
                                                        </Col>
                                                        <Col lg={4} className="infoRatingSectionGrid">
                                                            <h5 className="textNumber infoRatingSectionText">70</h5>
                                                            <p>Exclusive Projects</p>
                                                        </Col>
                                                        <Col lg={4} className="infoRatingSectionGrid">
                                                            <h5 className="textNumber infoRatingSectionText">300 +</h5>
                                                            <p>PropExperts</p>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="featured" title="Featured Developers">
                                        <section className="pt-0">
                                            <Container className="p-0">
                                                <Slider {...settings} className="">
                                                    {cardItems.map((card) => (
                                                        <div className="ProjectListAccordionSlider">
                                                            <Row className="m-0">
                                                                <div className="ProjectListAccordionLink">
                                                                    <Link to={card.link}><Image src={card.image} fluid className="" /></Link>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    ))}
                                                </Slider>
                                            </Container>
                                        </section>
                                    </Tab>
                                    <Tab eventKey="bps" title="BPS">
                                        <section className="pt-0">
                                            <div className="CheckYourSectionMob">
                                                <Link target="_blank" to="https://www.propex.ai/buy/profile?act=link"><Button>Check Your BPS Score</Button></Link>
                                            </div>

                                            <Container className="p-0">
                                                <div className="CheckYourDetailsBPS">
                                                    <h6>Buyer Profile Score (BPS)</h6>
                                                    <ul className="CheckYourList">
                                                        <li>Choose from a wide-array Properties to buy</li>
                                                        <li>Avail exciting discounts as a High-intent Prospect Buyer</li>
                                                        <li>Helps you make an informed decision to buy property</li>
                                                    </ul>
                                                </div>
                                            </Container>
                                        </section>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div>

            <Container fluid className="ProjectAboutMiddleSectionTop">
                <div className="ProjectTopSection">
                    <ul>
                        <li>
                            <Link to="/">
                                <Image src={HomeIcon} fluid className="homeicon" /><br /><span>Home</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/exhibition-hall/">
                                <Image src={ExpoIcon} fluid className="hallicon" /><br /><span>Hall</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/information-desk/">
                                <Image src={InfodeskIcon} fluid className="infoicon" /><br /><span>Info</span>
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </Container>

            <Container fluid className="ProjectAboutMiddleSection">
                <div className="Project-enquirynow d-none d-md-block">
                    <Link to="#">
                        <Image
                            onMouseEnter={() => handleButtonHover("Enquiry Now")}
                            onMouseLeave={handleButtonLeave}
                            onClick={togglePopupEN}
                            src={enquirynow}
                            fluid
                            className="d-none"
                        />
                        <div class="tooltip" onClick={togglePopupEN}>
                            <Image src={enquirynow} fluid className="" />
                            <span class="tooltiptext">Enquiry Now</span>
                        </div>
                    </Link>
                </div>
            </Container>

            {isOpenEN && (
                <EnquiryPopup
                    content={
                        <>
                            <Container>
                                <Row>
                                    <Col className="enquiry-image-col" lg={6} md={6} sm={6}>
                                        <Image src={enquirylogo} />
                                    </Col>
                                    <Col lg={6} md={6} sm={6} className="enquiry-content-text">
                                        <Formik
                                            initialValues={{
                                                fullname: "",
                                                email: "",
                                                phonenumber: "",
                                                // selectedOption: "",
                                            }}
                                            validate={values => {
                                                const errors = {}
                                                if (!values.fullname) {
                                                    errors.fullname = "Required"
                                                } else if (!values.phonenumber) {
                                                    errors.phonenumber = "Required"
                                                } else if (!values.email) {
                                                    errors.email = "Required"
                                                } else if (
                                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                                        values.email
                                                    )
                                                ) {
                                                    errors.email = "Invalid email address"
                                                }
                                                // else if (!values.selectedOption) {
                                                //   errors.selectedOption = "Atleast Select One"
                                                // }
                                                return errors
                                            }}
                                            onSubmit={(values, { setSubmitting }) => {
                                                setTimeout(() => {
                                                    EnquiryFormSubmit(values)

                                                    setSubmitting(false)
                                                }, 400)
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                isSubmitting,
                                            }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <div className="popup">
                                                        <h4 className="popup-header">Let's Connect</h4>
                                                        <Container className="p-0">
                                                            <Row className="w-100 m-0">
                                                                <div className="position-relative">
                                                                    <input
                                                                        className="Popup-input"
                                                                        type="text"
                                                                        name="fullname"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.fullname}
                                                                        placeholder="Full Name"
                                                                    />
                                                                    <span className="error-text">
                                                                        {errors.fullname &&
                                                                            touched.fullname &&
                                                                            errors.fullname}
                                                                    </span>
                                                                </div>

                                                                <div className="position-relative">
                                                                    <input
                                                                        className="Popup-input"
                                                                        type="tel"
                                                                        name="phonenumber"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.phonenumber}
                                                                        placeholder="Phone Number"
                                                                        maxLength={10}
                                                                    />
                                                                    <span className="error-text">
                                                                        {errors.phonenumber &&
                                                                            touched.phonenumber &&
                                                                            errors.phonenumber}
                                                                    </span>
                                                                </div>

                                                                <div className="position-relative">
                                                                    <input
                                                                        className="Popup-input"
                                                                        type="email"
                                                                        name="email"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.email}
                                                                        placeholder="E-mail Id"
                                                                    />
                                                                    <span className="error-text">
                                                                        {errors.email &&
                                                                            touched.email &&
                                                                            errors.email}
                                                                    </span>
                                                                </div>
                                                                <div className="w-100">
                                                                    <Button
                                                                        type="submit"
                                                                        className="Popup-submit"
                                                                        disabled={isSubmitting}
                                                                    >
                                                                        Book Now
                                                                    </Button>
                                                                </div>
                                                            </Row>
                                                        </Container>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    }
                    handleClose={togglePopupEN}
                ></EnquiryPopup>
            )}

            {showSuccessModal && <SuccessModal closeModal={closeModal} />}
        </>
    )
}

export default InformationDesk