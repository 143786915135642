import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function InfoReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <p className="mb-0">
                <h4>About Proverse</h4>
                <h6>Reinventing Real Estate, through virtual experiences.</h6>
                <p className="AboutExpoPara">PropEx.ai India’s 1st property exchange presents PropVerse!</p>

                <p className="AboutExpoPara">PropVerse redefines property expos by magnifying the expo experience virtually & brings global reach to your fingertips.</p>

                <p className="AboutExpoPara">PropVerse is an exceptional showcase of hand-picked top developers & property buyers can discover new projects from an extensive collection of properties and network with the important stakeholders of the real estate ecosystem.</p>
            </p>

            {/* {isShowMore && (
                <p className="mb-0">
                    <p className="AboutExpoPara">The company expanded itself with fields which may or may not be related to real estate. The Morph Design Company provides exceptional interior design services.</p>

                    <p className="AboutExpoPara">The Sobha Group was founded in 1986 by brothers Irfan Razack, Rezwan Razack and Noaman Razack. The group is one of India’s leading real estate companies. The Prestige Group have taken the South Indian real estate market by storm. They are known as the best real estate developers in Karnataka.</p>

                    <p className="AboutExpoPara">The company expanded itself with fields which may or may not be related to real estate. The Morph Design Company provides exceptional interior design services. </p>

                    <p className="AboutExpoPara">The Shoba Group was founded in 1986 by brothers Irfan Razack, Rezwan Razack and Noaman Razack. The group is one of India’s leading real estate companies. The Prestige Group have taken the South Indian real estate market by storm. They are known as the best real estate developers in Karnataka.</p>

                    <p className="AboutExpoPara">The company expanded itself with fields which may or may not be related to real estate. The Morph Design Company provides exceptional interior design services. </p>
                </p>
            )} */}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default InfoReadMore;