import React, { useState } from 'react';
import "./assets/styles/_index.scss"
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from "gatsby"
import InfoReadMore from "../informationdesk/InfoReadMore"

import Slider from "react-slick"
import prevArrow from '/utility/assets/informationdesk/prevArrow.png'
import nextArrow from '/utility/assets/informationdesk/nextArrow.png'

// import AbheeVenturesLogo from "./assets/images/developers/Abhee-Ventures.png";
// import AssetzLogo from "./assets/images/developers/Assetz.png";
// import BCDLogo from "./assets/images/developers/BCD.png";
// import BrigadeLogo from "./assets/images/developers/Brigade.png";
// import CasagrandLogo from "./assets/images/developers/Casagrand.png";
// import GoorejLogo from "./assets/images/developers/Goorej.png";

const Brigade = require("/utility/assets/informationdesk/developers/Brigade-Group.png")
const PrestigeGroup = require("/utility/assets/informationdesk/developers/Prestige-Group.png")
const PropexLogo = require("/utility/assets/informationdesk/propex-logo.png")

const info1 = require("/utility/assets/informationdesk/developers/Abhee-Ventures.png")
const info2 = require("/utility/assets/informationdesk/developers/Arsis.png")
const info3 = require("/utility/assets/informationdesk/developers/Assetz.png")
const info4 = require("/utility/assets/informationdesk/developers/BCD.png")
const info5 = require("/utility/assets/informationdesk/developers/Birla-Estates.png")
const info6 = require("/utility/assets/informationdesk/developers/Brigade.png")
const info7 = require("/utility/assets/informationdesk/developers/Casagrand.png")
const info8 = require("/utility/assets/informationdesk/developers/Geown.png")
const info9 = require("/utility/assets/informationdesk/developers/Goorej.png")
const info10 = require("/utility/assets/informationdesk/developers/Infracon-Pvt.png")

const info11 = require("/utility/assets/informationdesk/developers/Maa-Homes.png")
const info12 = require("/utility/assets/informationdesk/developers/Preeti-Developers.png")
const info13 = require("/utility/assets/informationdesk/developers/Ramky.png")
const info14 = require("/utility/assets/informationdesk/developers/Sowparnika.png")
const info15 = require("/utility/assets/informationdesk/developers/Sanjeevini-Group.png")
const info16 = require("/utility/assets/informationdesk/developers/Sattva.png")
const info17 = require("/utility/assets/informationdesk/developers/Shoba.png")
const info18 = require("/utility/assets/informationdesk/developers/Provident.png")
const info19 = require("/utility/assets/informationdesk/developers/ColiveAarra.png")
const info20 = require("/utility/assets/informationdesk/developers/Newry.png")
const info21 = require("/utility/assets/informationdesk/developers/Fortune.png")
const info22 = require("/utility/assets/informationdesk/developers/Hardrock.png")
const info23 = require("/utility/assets/informationdesk/developers/Kaman.png")

const cardItems = [
  { id: 1, image: info1, link: "/developers/abhee-developer/" },
  { id: 2, image: info2, link: "/developers/arsis-developer/" },
  { id: 3, image: info3, link: "/developers/assetz-developer/" },
  { id: 4, image: info4, link: "/developers/bcd-emprasa-developer/" },
  { id: 5, image: info5, link: "/developers/birla-estates-developer/" },
  { id: 6, image: info6, link: "/developers/brigade-developer/" },
  { id: 7, image: info7, link: "/developers/casagrand-developer/" },
  { id: 8, image: info8, link: "/developers/geown-developer/" },
  { id: 9, image: info9, link: "/developers/godrej-developer/" },
  { id: 10, image: info10, link: "/developers/infracon-developer/" },
  { id: 11, image: info11, link: "/developers/maa-homes-developer/" },
  { id: 12, image: info12, link: "/developers/preeti-developer/" },
  { id: 13, image: info13, link: "/developers/ramky-developer/" },
  { id: 14, image: info14, link: "/developers/sowparnika-developer/" },
  { id: 15, image: info15, link: "/developers/sanjeevini-developer/" },
  { id: 16, image: info16, link: "/developers/sattva-developer/" },
  { id: 17, image: info17, link: "/developers/sobha-developer/" },
  { id: 18, image: info18, link: "/developers/provident-developer/" },
  { id: 19, image: info19, link: "/developers/aarra-developer/" },
  { id: 20, image: info20, link: "/developers/newry-developer/" },
  { id: 21, image: info21, link: "/developers/fortune-sensation-developer/" },
  { id: 22, image: info22, link: "/developers/hard-rock-developer/" },
  { id: 23, image: info23, link: "/developers/kaman-developer/" }
];

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, width: '5px', height: '5px', backgroundImage: `url(${prevArrow})` }}
      onClick={onClick}
    />
  );
}

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, width: '5px', height: '5px', backgroundImage: `url(${nextArrow})` }}
      onClick={onClick}
    />
  );
}

const InfoSidebar = ({ isOpen, toggleRightSidebar }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "0px",
    centerMode: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 3,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },

    ],

  }


  return (
    <div className={`right-sidebar ${isOpen ? 'open' : ''}`}>

      <div className="infoBackButton" onClick={toggleRightSidebar}><span>
        <Link to="/"><ArrowLeft /> Back</Link></span>
      </div>

      <div className="infoAboutExpo">
        <InfoReadMore />
      </div>

      <div className="infoRatingSection">
        <Container>
          <Row lg={12} className="infoRatingSectionWrap">
            <Col lg={4} className="infoRatingSectionGrid">
              <h5 className="textNumber infoRatingSectionText">20</h5>
              <p>Top Developers</p>
            </Col>
            <Col lg={4} className="infoRatingSectionGrid">
              <h5 className="textNumber infoRatingSectionText">70</h5>
              <p>Exclusive Projects</p>
            </Col>
            <Col lg={4} className="infoRatingSectionGrid">
              <h5 className="textNumber infoRatingSectionText">300 +</h5>
              <p>PropExperts</p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="infoFeaturedSection">
        <h6>Featured Developers</h6>

        <Row>
          <section className="Rooms section-white pt-0">
            <Container>
              <Slider {...settings} className="rooms-blues-slider">
                {cardItems.map((card) => (
                  <div className="ProjectListAccordionSlider">
                    <Row className="m-0">
                      <div className="ProjectListAccordionLink">
                        <Link to={card.link}><Image src={card.image} fluid className="" /></Link>
                      </div>
                    </Row>
                  </div>
                ))}
              </Slider>
            </Container>

          </section>
        </Row>
      </div>

      <div className="infoHallSection">
        <Link to="/exhibition-hall/"><Button>Go to Exhibition Hall</Button></Link>
      </div>

      <div className="CheckYourDetails">
        <h6>Buyer Profile Score (BPS)</h6>
        <ul className="CheckYourList">
          <li>Choose from a wide-array Properties to buy</li>
          <li>Avail exciting discounts as a High-intent Prospect Buyer</li>
          <li>Helps you make an informed decision to buy property</li>
        </ul>
      </div>

      <div className="CheckYourSection">
        <Link target="_blank" to="https://www.propex.ai/buy/profile?act=link"><Button>Check Your BPS Score</Button></Link>
      </div>

      {/* <div className='PropexLogo'>
        <Link to="https://www.propex.ai/" target='_blank'><Image src={PropexLogo} fluid className="" /></Link>
      </div> */}
    </div>
  );
};

export default InfoSidebar;
